import { useTheme } from "@emotion/react";
import { Box, Button, Skeleton, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const DesktopSection = () => {
  const goTo = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isHovering, setIsHovering] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const logoStyle = {
    width: isSmallScreen ? "90%" : "80%",
    cursor: "pointer",
    transition: "transform 0.3s ease",
    transform: isHovering ? "scale(0.95)" : "scale(1)",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        marginY: "30vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column-reverse" : "row",
          width: "90%",
          zIndex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          top: isSmallScreen ? "8rem" : "50%",
          transform: isSmallScreen ? "none" : "translateY(-50%)",
          textAlign: "right",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: isSmallScreen ? "100%" : "50%",
            justifyContent: isSmallScreen ? "center" : "left",
            paddingY: "2rem",
            position: 'relative',
          }}
        >
          {!imageLoaded && (
            <Skeleton
              variant="rectangular"
              width={isSmallScreen ? "90%" : "80%"}
              height="100%"
              sx={{ position: "absolute" }}
              animation="wave"
            />
          )}
          <img
            alt="App Escritorio Traductor de Voz a Lengua de Señas"
            src="/static/images/mock04.webp"
            onClick={() => {
              goTo("/download-app-pc");
            }}
            style={logoStyle}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onLoad={handleImageLoad}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: isSmallScreen ? "100%" : "50%",
            height: isSmallScreen && "60vh",
            justifyContent: "center",
            flexDirection: "column",
            paddingY: "2rem",
          }}
        >
          <Typography variant="h3" sx={{ letterSpacing: "0.1rem" }}>
            Descarga nuestra
          </Typography>
          <Typography variant="h3" sx={{ letterSpacing: "0.1rem" }}>
            herramienta
          </Typography>
          <Typography variant="h3" sx={{ letterSpacing: "0.1rem" }}>
            de escritorio
          </Typography>
          <Box sx={{ marginTop: "2rem", width: "100%" }}>
            <Typography variant="body1" sx={{ width: "100%" }}>
              Accede a nuestra herramienta de grabación de señas personalizadas
            </Typography>
            <Typography variant="body1" sx={{ width: "100%" }}>
              - Para Windows, Linux y Mac OS.
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{ marginTop: "2rem" }}
              onClick={() => {
                goTo("/download-app-pc");
              }}
            >
              <Typography>Descargala ahora !</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopSection;
