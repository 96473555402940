import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const WelcomeSection = () => {
  const goTo = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isHovering, setIsHovering] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const logoStyle = {
    width: isSmallScreen ? "90%" : "80%",
    justifyContent: 'center',
    cursor: "pointer",
    transition: "transform 0.3s ease",
    transform: isHovering ? "scale(0.95)" : "scale(1)",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        width: "100%",
        minHeight: "100vh",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        textAlign: "left",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: isSmallScreen ? "90%" : "50%",
          height: isSmallScreen && "90vh",
          justifyContent: "center",
          flexDirection: "column",
          paddingLeft: isSmallScreen ? "3rem" : "4rem",
          paddingRight: isSmallScreen ? "3rem" : "2rem",
        }}
      >
        <Typography variant="h3" sx={{ letterSpacing: "0.1rem" }}>
          Una seña
        </Typography>
        <Typography
          variant="h3"
          sx={{ letterSpacing: "0.1rem", color: "primary.main" }}
        >
          al alcance
        </Typography>
        <Typography variant="h3" sx={{ letterSpacing: "0.1rem" }}>
          de tu voz
        </Typography>
        <Box sx={{ marginTop: "2rem", width: "100%" }}>
          <Typography variant="body1" sx={{ width: "100%" }}>
            Convierte tu voz en gestos claros y comprensibles al instante,
            eliminando las barreras de comunicación. Únete a nosotros para crear
            un mundo más inclusivo y conectado
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{ marginTop: "2rem" }}
            onClick={() => {
              goTo("/login");
            }}
          >
            <Typography>Registrate ahora !</Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: isSmallScreen ? "100%" : "50%",
          justifyContent: isSmallScreen ? "center" : "right",
          alignItems: "center",
          marginRight: isSmallScreen ? "" : "4rem",
          marginY: isSmallScreen && "2rem",
          position: "relative",
        }}
      >
        {!imageLoaded && (
          <Skeleton
            variant="rectangular"
            width={isSmallScreen ? "90%" : "80%"}
            height="100%"
            sx={{ position: "absolute" }}
            animation="wave"
          />
        )}
        <img
          alt="Mock Traductor LSM"
          src="/static/images/mock01.webp"
          onClick={() => {
            goTo("/login");
          }}
          style={logoStyle}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onLoad={handleImageLoad}
        />
      </Box>
    </Box>
  );
};

export default WelcomeSection;
