import React, { useState } from "react";
import AppRouter from './routes/AppRouter';
import { ThemeProvider, createTheme } from "@mui/material";
import { AuthProvider } from "./auth/AuthProvider";

function App() {
  const [darkMode] = useState(false);

  const lightTheme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#528faa',
      },
      secondary: {
        main: '#ff914d',
      },
      error: {
        main: '#d50000',
      },
    }
  });

  const darkTheme = createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#2874b9',
      },
      secondary: {
        main: '#ff1744',
      },
      error: {
        main: '#d50000',
      },
    },
  });

  const theme = darkMode ? darkTheme : lightTheme;

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;