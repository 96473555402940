import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const FooterSection = () => {
  const goTo = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "20vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonBase
        sx={{
          width: isSmallScreen ? "80%" : "50%",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={() => {
          handleLogoClick();
        }}
      >
        <img
          alt="Logo Traductor LSM"
          src="/static/images/logo.webp"
          style={{ width: isSmallScreen ? "100%" : "80%" }}
        />
      </ButtonBase>
      <hr
        style={{
          width: "90%",
          borderBottomWidth: "1rem",
          borderColor: "#3A3A3A",
          borderWidth: "0.2rem",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "normal",
          textAlign: "center",
          marginBottom: "5vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ color: "#3A3A3A", fontWeight: 600 }}>
            Web
          </Typography>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              color: "#3A3A3A",
              "&:hover": {
                color: "secondary.main",
              },
            }}
            onClick={() => {
              goTo("/login");
            }}
          >
            <Typography variant="body1">Iniciar Sesión</Typography>
          </Button>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              color: "#3A3A3A",
              "&:hover": {
                color: "secondary.main",
              },
            }}
            onClick={() => {
              goTo("/registro");
            }}
          >
            <Typography variant="body1">Registrarse</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "4rem",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ color: "#3A3A3A", fontWeight: 600 }}>
            App Móvil
          </Typography>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              color: "#3A3A3A",
              "&:hover": {
                color: "secondary.main",
              },
            }}
            onClick={() => {
              goTo("/");
            }}
          >
            <Typography variant="body1">Descargar</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "4rem",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ color: "#3A3A3A", fontWeight: 600 }}>
            App Escritorio
          </Typography>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              color: "#3A3A3A",
              "&:hover": {
                color: "secondary.main",
              },
            }}
            onClick={() => {
              goTo("/");
            }}
          >
            <Typography variant="body1">Descargar</Typography>
          </Button>
        </Box>
      </Box>
    </footer>
  );
};

export default FooterSection;
