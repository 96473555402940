import React, { useEffect, useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MicIcon from '@mui/icons-material/Mic';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import LogoutIcon from '@mui/icons-material/Logout';
import { Paper } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import { AUTH_URL } from '../auth/constants';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

export default function NavBarBottom() {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const auth = useAuth();

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location.pathname]);

  async function handleSignOut(event) {
    event.preventDefault();

    try {
      const response = await fetch(`${AUTH_URL}/signout`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getRefreshToken()}`,
        },
      });

      if (response.ok) {
        auth.signOut();
      } else {
        throw new Error(response.json.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        zIndex: '2',
      }}
      elevation={0}
    >
      <BottomNavigation
        showLabels
        value={activePage}
        onChange={(event, newValue) => setActivePage(newValue)}
        sx={{
          backgroundColor: 'transparent',
        }}
      >
        <BottomNavigationAction
          label="Traducir"
          value="/traductor"
          icon={<MicIcon />}
          component={Link}
          showLabel={false}
          to="/traductor"
          selected={activePage === "/traductor"}
          sx={{
            color: '#000',
            '&.Mui-selected': {
              color: '#000',
            }
          }}
        />
        <BottomNavigationAction
          label="Grabar"
          value="/grabar"
          icon={<VideoCameraFrontIcon />}
          component={Link}
          showLabel={false}
          selected={activePage === "/grabar"}
          to="/grabar"
          sx={{
            color: '#000',
            '&.Mui-selected': {
              color: '#000',
            }
          }}
        />
        <BottomNavigationAction
          label="Traducciones"
          value="/traducciones"
          icon={<VideoLibraryIcon />}
          component={Link}
          showLabel={false}
          selected={activePage === "/traducciones"}
          to="/traducciones"
          sx={{
            color: '#000',
            '&.Mui-selected': {
              color: '#000',
            }
          }}
        />
        <BottomNavigationAction
          label="Salir"
          value="salir"
          icon={<LogoutIcon />}
          component={Link}
          showLabel={false}
          onClick={handleSignOut}
          sx={{
            color: '#000',
            '&.Mui-selected': {
              color: '#000',
            }
          }}
        />
      </BottomNavigation>
    </Paper>
  );
}