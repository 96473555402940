import React, { useContext, createContext, useState, useEffect } from "react";
import { AUTH_URL } from "./constants";
import LinearIndeterminate from "../components/LinearIndeterminate";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
    isAuthenticated: false,
    isAdmin: false,
    getAccessToken: () => "",
    saveUser: (userData) => { },
    getRefreshToken: () => { },
    getUser: () => { },
    signOut: () => { },
});

export function AuthProvider({ children }) {
    const goTo = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [accessToken, setAccessToken] = useState(false);
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        checkAuth();
    }, []);

    async function requestNewAccessToken(refreshToken) {
        try {
            const response = await fetch(`${AUTH_URL}/refresh-token`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${refreshToken}`,
                }
            });
            if (response.ok) {
                const json = await response.json();

                if (json.error) {
                    throw new Error(json.error);
                }

                return json.body.accessToken;
            } else {
                return response.statusText;
            }
        } catch (error) {
            return null;
        }
    }

    async function getUserInfo(accessToken) {
        try {
            const response = await fetch(`${AUTH_URL}/user`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                const json = await response.json();
                return json.body;
            } else {
                if (response.status === 401) {
                    signOut();
                    return null;
                }
            }
        } catch (error) {
            return new Error('Conexión con el servidor fallida !');
        }
    }

    async function checkAuth() {
        setIsLoading(true);
        if (accessToken) {
            try {
                const userInfo = await getUserInfo(accessToken);
                if (userInfo) {
                    saveSessionInfo(userInfo, accessToken, !getRefreshToken());
                    setIsLoading(false);
                    return;
                }
            } catch (error) {
                return null;
            }
        } else {
            const token = getRefreshToken();
            if (token) {
                const newAccessToken = await requestNewAccessToken(token);

                if (newAccessToken) {
                    try {
                        const userInfo = await getUserInfo(newAccessToken);
                        if (userInfo) {
                            saveSessionInfo(userInfo, newAccessToken, token);
                            setIsLoading(false);
                            return;
                        }
                    } catch (error) {
                        return null;
                    }
                }
            }
        }
        setIsLoading(false);
    }

    function saveSessionInfo(userInfo, accessToken, refreshToken) {
        setAccessToken(accessToken);
        setUser(userInfo);
        if (userInfo.is_admin) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
        localStorage.setItem("token", JSON.stringify(refreshToken));
        setIsAuthenticated(true);
    }

    function getAccessToken() {
        return accessToken;
    }

    function getRefreshToken() {
        const tokenData = localStorage.getItem("token");
        if (tokenData) {
            const token = JSON.parse(tokenData);
            return token;
        }
        return null;
    }

    function saveUser(userData) {
        saveSessionInfo(
            userData.body.user,
            userData.body.accessToken,
            userData.body.refreshToken,
        );
    }

    function getUser() {
        return user;
    }

    async function signOut() {
        try {
            const response = await fetch(`${AUTH_URL}/signout`, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getRefreshToken()}`,
                },
            });

            if (response.ok) {
                setIsAuthenticated(false);
                setIsAdmin(false);
                setAccessToken("");
                setUser(undefined);
                localStorage.removeItem("token");
                goTo('/');
            } else {
                throw new Error(response.json.error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    function getApiKey() {
        return user.api_key;
    }

    return (
        <AuthContext.Provider value={{ isAdmin, isAuthenticated, getAccessToken, saveUser, getRefreshToken, getUser, signOut, getApiKey }}>
            {isLoading ? <LinearIndeterminate /> : children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);