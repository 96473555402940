import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomCopyright from './CustomCopyright';
import { AUTH_URL } from '../auth/constants';
import { useNavigate } from 'react-router-dom';

export default function RegisterForm({ onErrorResponse, onLoading, onErrorSeverity }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showErrorFirstName, setShowErrorFirstName] = useState(false);
    const [showErrorLastName, setShowErrorLastName] = useState(false);
    const [showErrorUsername, setShowErrorUsername] = useState(false);
    const [showErrorEmail, setShowErrorEmail] = useState(false);
    const [showErrorPassword, setShowErrorPassword] = useState(false);
    const [errorFirstName, setErrorFirstName] = useState("");
    const [errorLastName, setErrorLastName] = useState("");
    const [errorUsername, setErrorUsername] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const goTo = useNavigate();

    function isValidName(name) {
        const regex = /^[A-Za-z\s]+$/;
        return regex.test(name);
    }

    function isValidEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    // 8 characters, one uppercase letter, one lowercase letter and one number
    function isValidPassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        return regex.test(password);
    }    

    async function handleSubmit(event) {
        event.preventDefault();

        if (!isValidName(firstName)) {
            setShowErrorFirstName(true);
            setErrorFirstName("Solo letras y espacios");
            return;
        } else {
            setShowErrorFirstName(false);
            setErrorFirstName("");
        }

        if (!isValidName(lastName)) {
            setShowErrorLastName(true);
            setErrorLastName("Solo letras y espacios");
            return;
        } else {
            setShowErrorLastName(false);
            setErrorLastName("");
        }

        

        if (!isValidEmail(email)) {
            setShowErrorEmail(true);
            setErrorEmail("Correo invalido");
            return;
        } else {
            setShowErrorEmail(false);
            setErrorEmail("");
        }
        
        if (!isValidPassword(password)) {
            setShowErrorPassword(true);
            setErrorPassword("Debe contener mínimo 8 caracteres, una mayúscula, una minúscula, y un número");
            return;
        } else {
            setShowErrorPassword(false);
            setErrorPassword("");
        }

        try {
            const name = firstName + " " + lastName;
            onLoading(true);

            const response = await fetch(`${AUTH_URL}/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    username,
                    email,
                    password,
                }),
            });

            if (response.ok) {
                onErrorResponse("Usuario creado exitosamente !");
                onErrorSeverity('success');
                setFirstName("");
                setLastName("");
                setUsername("");
                setEmail("");
                setPassword("");
                onLoading(false);

                setTimeout(() => {
                    goTo('/login');
                }, 3000);
            } else {
                const json = await response.json();
                const error = json.body.error;
                if (error === "El nombre de usuario ya existe !") {
                    setShowErrorUsername(true);
                    setErrorUsername('El nombre de usuario ya existe !');
                    onLoading(false);
                    return;
                }
                onErrorResponse(error);
                onErrorSeverity('error');
                onLoading(false);
                return;
            }
        } catch (error) {
            onErrorResponse(error);
            onErrorSeverity('error');
            onLoading(false);
            return;
        }
    };

    return (
        <Box>
            <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', paddingBottom: '1rem' }}>
                Registrarte
            </Typography>
            <Typography variant="subtitle1" sx={{ paddingBottom: '2rem' }} >
                Bienvenido !
                Ingresa los siguientes datos para registrarte
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} padding='1rem'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="given-name"
                            name="firstName"
                            required
                            fullWidth
                            id="firstName"
                            label="Nombre(s)"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            autoFocus
                            error={showErrorFirstName}
                            helperText={errorFirstName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="lastName"
                            label="Apellidos"
                            name="lastName"
                            autoComplete="family-name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            error={showErrorLastName}
                            helperText={errorLastName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="username"
                            label="Nombre de usuario"
                            name="username"
                            autoComplete="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            error={showErrorUsername}
                            helperText={errorUsername}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Correo Electronico"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={showErrorEmail}
                            helperText={errorEmail}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={showErrorPassword}
                            helperText={errorPassword}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Registrarme
                </Button>
            </Box>
            <CustomCopyright sx={{ mt: 8, mb: 4 }} />
        </Box>
    );
}