import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import WelcomeSection from "../components/WelcomeSection";
import AppSection from "../components/AppSection";
import PageSection from "../components/PageSection";
import DesktopSection from "../components/DesktopSection";
import FooterSection from "../components/FooterSection";
import { Navigate, useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "./Landpage.css";
import { useAuth } from "../auth/AuthProvider";

const AnimatedSection = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.02,
  });

  return (
    <Box
      ref={ref}
      sx={{
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(100px)",
        transition: "opacity 0.5s, transform 0.5s",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export default function Landpage() {
  const goTo = useNavigate();

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  };

  return (
    <Box
      sx={{
        backgroundColor: "#DFDFDF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <AppBar sx={{ bgcolor: "rgba(223, 223, 223, 0.8)", position: "fixed" }}>
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <img
            alt="Logo"
            src="/static/images/logo.webp"
            onClick={handleLogoClick}
            style={{
              objectFit: "contain",
              width: "20%",
              marginLeft: "2rem",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
              cursor: "pointer",
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="text"
              sx={{
                marginRight: "2rem",
              }}
              onClick={() => {
                goTo("/login");
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textTransform: "none",
                  color: "#3A3A3A",
                  "&:hover": {
                    color: "secondary.main",
                  },
                }}
              >
                Iniciar sesión
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          opacity: 1,
          width: "100%",
          height: "100vh",
          position: "relative",
          zIndex: 1,
        }}
      >
        <WelcomeSection />
        <AnimatedSection>
          <AppSection />
        </AnimatedSection>
        <AnimatedSection>
          <PageSection />
        </AnimatedSection>
        <AnimatedSection>
          <DesktopSection />
        </AnimatedSection>
        <AnimatedSection>
          <FooterSection />
        </AnimatedSection>
      </Box>
      <Box className="background">
        <Box className="sphere sphere1"></Box>
        <Box className="sphere sphere2"></Box>
        <Box className="sphere sphere3"></Box>
      </Box>
    </Box>
  );
}
