import React from "react";
import { Route, Routes } from "react-router-dom";
import AppBarCustom from "../components/AppBarCustom";
import Login from "./Login";
import Traductor from "./Traductor";
import Grabar from "./Grabar";
import Landpage from "./Landpage";
import NavBarBottom from "../components/NavBarBottom";
import { Box } from "@mui/material";
import NotFound from "../components/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import Traducciones from "./Traducciones";
import Admin from "./Admin";

export default function AppRouter() {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <Box>
                        <AppBarCustom />
                        <ProtectedRoute />
                        <NavBarBottom />
                    </Box>
                }
            >
                <Route
                        path='/traductor'
                        element={ <Traductor/> }
                />
                <Route
                    path='/grabar'
                    element={ <Grabar /> }
                />
                <Route
                    path='/traducciones'
                    element={ <Traducciones /> }
                />
                <Route
                    path="/admin"
                    element={ <Admin /> }
            />
            </Route>
            <Route index element={<Landpage />} />
            <Route path='landpage' element={<Landpage />} />
            <Route path='login' element={<Login />} />
            <Route path='registro' element={<Login />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}