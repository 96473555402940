import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoginImage from "../resources/images/LoginImage.png";
import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/RegisterForm";
import "./Login.css";
import { useAuth } from "../auth/AuthProvider";
import { Navigate, useLocation } from "react-router-dom";
import CustomAlert from "../components/CustomAlert";
import LinearIndeterminate from "../components/LinearIndeterminate";

export default function Login() {
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [showError, setShowError] = useState(false);
  const [severity, setSeverity] = useState('info');
  const [loading, setLoading] = useState(false);
  let auth = useAuth();

  useEffect(() => {
    setShowForm(location.pathname.startsWith("/registro"));
  }, [location.pathname]);

  useEffect(() => {
    if (errorResponse) {
      setShowError(true);
      setTimeout(() => {
        setErrorResponse("");
        setShowError(false);
      }, 4000);
    } else {
      setShowError(false);
    }
  }, [errorResponse]);

  const handleOnLoading = (isLoading) => {
    setLoading(isLoading);
  };

  const handleOnErrorResponse = (response) => {
    setErrorResponse(response);
  };

  const handleOnErrorSeverity = (severity) => {
    setSeverity(severity);
  };


  const toggleForm = () => {
    const newPath = showForm ? "/login" : "/registro";
    setShowForm(!showForm);
    window.history.pushState({}, "", newPath);
  };

  if (auth.isAuthenticated) {
    return <Navigate to="/traductor" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#DFDFDF",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          width: "80%",
          height: "80%",
          boxShadow: "-10px 10px 15px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button
                sx={{
                  marginX: "1rem",
                  marginTop: "1rem",
                }}
                onClick={toggleForm}
              >
                {showForm ? "Iniciar sesión" : "Registrarte"}
              </Button>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "left",
                padding: "4rem",
              }}
            >
              {showForm ? (
                <RegisterForm
                  onErrorResponse={handleOnErrorResponse}
                  onLoading={handleOnLoading}
                  onErrorSeverity={handleOnErrorSeverity}
                />
              ) : (
                <LoginForm
                  onErrorResponse={handleOnErrorResponse}
                  onLoading={handleOnLoading}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                position: "relative",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <img
                  src={LoginImage}
                  alt="Imagen de inicio de sesión"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box
                style={{
                  zIndex: 1,
                  color: "#FFFFFF",
                  padding: "2rem",
                  bottom: 0,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", paddingBottom: "0.5rem" }}
                >
                  Traductor de Voz a LSM
                </Typography>
                <Typography variant="body1">
                  Comienza a traducir, inicia sesión, o registrate ahora !
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomAlert
        error={showError}
        errorMessage={errorResponse}
        severity={severity}
        duration="4000"
      />
      {loading && <LinearIndeterminate />}
    </Box>
  );
}
