import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MicIcon from "@mui/icons-material/Mic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, Typography, Button, Container } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DialogSliderCustom from "../components/DialogSliderCustom";
import ShareIcon from "@mui/icons-material/Share";
import { useAuth } from "../auth/AuthProvider";
import { MICROSERVICE01_URL, SERVER_VIDEOS_URL } from "../auth/constants";
import CustomAlert from "../components/CustomAlert";
import { useNavigate } from "react-router-dom";
import LinearIndeterminate from "../components/LinearIndeterminate";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const recognition = new SpeechRecognition();
recognition.continuous = true;
recognition.interimResults = true;
recognition.lang = "es-MX";

export default function Traductor() {
  const goTo = useNavigate();
  const [isMicrophonePermissionGranted, setIsMicrophonePermissionGranted] =
    useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [showTranslator, setShowTranslator] = useState(false);
  let auth = useAuth();

  const handleCloseLightbox = () => {
    setLightboxOpen(false);
  };

  async function checkUserAlphabet() {
    try {
      setLoading(true);
      const url = `${SERVER_VIDEOS_URL}/api/get-user-videos-list/${
        auth.getUser().username
      }/${
        auth.getApiKey()
      }`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(url, options);
      if (response.ok) {
        setShowTranslator(true);
        setLoading(false);
        return;
      } else {
        setShowTranslator(false);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        "No ha sido posible conectar con el servidor.\n\nIntenta mas tarde."
      );
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 4000);
      return;
    }
  }

  const checkMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setIsMicrophonePermissionGranted(true);
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    checkMicrophonePermission();

    checkUserAlphabet();
  }, []);

  function startRecording() {
    checkMicrophonePermission();
    if (!isMicrophonePermissionGranted) {
      setOpenDialog(true);
      return;
    }
    setIsRecording(true);
    setExpanded(true);
    setTranscript("");
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const audioContext = new AudioContext();

        const source = audioContext.createMediaStreamSource(stream);

        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        source.connect(analyser);

        const canvas = document.getElementById("waveform");
        const canvasCtx = canvas.getContext("2d");
        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        function drawWaveform() {
          requestAnimationFrame(drawWaveform);

          analyser.getByteTimeDomainData(dataArray);

          canvasCtx.fillStyle = "transparent";
          canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

          canvasCtx.lineWidth = 3;
          canvasCtx.strokeStyle = "#DFDFDF";
          canvasCtx.beginPath();

          let x = 0;
          for (let i = 0; i < bufferLength; i++) {
            const v = dataArray[i] / 128.0;
            const y = (v * HEIGHT) / 2;

            if (i === 0) {
              canvasCtx.moveTo(x, y);
            } else {
              canvasCtx.lineTo(x, y);
            }

            x += 3;
          }

          canvasCtx.lineTo(canvas.width, canvas.height / 2);
          canvasCtx.stroke();
        }

        drawWaveform();

        recognition.start();
        setTimeout(() => {
          recognition.stop();
        }, 10000);
      });
  }

  function stopRecording() {
    setIsRecording(false);
    setExpanded(false);
    recognition.stop();
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      });
  }

  recognition.onresult = (event) => {
    const currentTranscript = Array.from(event.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join("");

    if (currentTranscript.length > 100) {
      stopRecording();
      return;
    }
    setTranscript(currentTranscript);
  };

  recognition.onspeechend = () => {
    setIsRecording(false);
    setExpanded(false);
    recognition.stop();
  };

  recognition.onend = () => {
    setIsRecording(false);
    setIsLoading(false);
    setExpanded(false);
    if (transcript.trim() === "") {
      setErrorMessage(
        "No se ha reconocido ninguna transcripción.\n\nIntenta de nuevo !"
      );
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 4000);
    } else {
      callPythonApi();
    }
  };

  recognition.onerror = (event) => {
    setErrorMessage("Error en el reconocimiento de voz !");
    setIsRecording(false);
    setTranscript("");
    setExpanded(false);
    setError(true);

    setTimeout(() => {
      setError(false);
    }, 4000);
  };

  function shareVideo() {
    if (navigator.share) {
      navigator
        .share({
          title: "Compartir video generado",
          url: videoUrl,
        })
        .then(() => console.log("Video compartido"))
        .catch((error) => console.error(`Error al compartir video: ${error}`));
    } else {
      setErrorMessage(
        "La función de compartir no está disponible en este navegador."
      );
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  }

  async function callPythonApi() {
    setIsLoading(true);
    const response = await fetch(`${MICROSERVICE01_URL}/api/process_text/${auth.getApiKey()}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: auth.getUser().username,
        text: transcript,
      }),
    }).catch((error) => {
      setErrorMessage("El servicio no se encuentra disponible por el momento, intentalo más tarde.");
      setError(true);
      setIsLoading(false);
      setTranscript("");

      setTimeout(() => {
        setError(false);
      }, 4000);
      window.location.reload();
    });
    if (response.ok) {
      const data = await response.json();
      const filename = data.filename;
      const videoUrl = `${SERVER_VIDEOS_URL}/results/${
        auth.getUser().username
      }/${filename}`;
      setVideoUrl(videoUrl);
      setLightboxOpen(true);
      setTranscript("");
      setIsLoading(false);
    } else {
      setErrorMessage("El servicio no se encuentra disponible por el momento, intentalo más tarde.");
      setError(true);
      setIsLoading(false);
      setTranscript("");

      setTimeout(() => {
        setError(false);
      }, 4000);
      window.location.reload();
    }
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        height: "100vh",
        minWidth: "100%",
      }}
    >
      {showTranslator ? (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <Button
                variant="contained"
                aria-label="traducir voz a lsm"
                color={expanded ? "secondary" : "primary"}
                sx={{
                  cursor: isLoading ? "not-allowed" : "pointer",
                }}
                onClick={isRecording ? stopRecording : startRecording}
                disabled={isLoading ? true : false}
              >
                {isLoading ? (
                  <> Generando...</>
                ) : isRecording ? (
                  "Grabando..."
                ) : (
                  "Grabar"
                )}
                <Box marginRight="0.5rem" />
                <MicIcon />
              </Button>
              {isRecording && <canvas id="waveform" />}
            </Box>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              {transcript}
            </Typography>
            <Box
              id="video-container"
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "100vw",
                width: "100%",
              }}
            >
              {lightboxOpen && (
                <Box
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    zIndex: 9999,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleCloseLightbox}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#1A1A1A",
                      borderRadius: "8px",
                      width: "60%",
                      height: "auto",
                      padding: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Typography variant="body1" color="#DFDFDF">
                      Video generado
                    </Typography>
                    <hr style={{ width: "100%", borderColor: "#DFDFDF" }} />
                    <video
                      src={videoUrl}
                      controls
                      style={{ width: "80%", height: "auto", maxHeight: '60vh' }}
                    />
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{
                              marginTop: "1rem",
                            }}
                            onClick={handleCloseLightbox}
                            variant="contained"
                            color="success"
                          >
                            Volver
                            <Box marginRight="0.5rem" />
                            <ArrowBackIcon />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{
                              marginTop: "1rem",
                            }}
                            onClick={shareVideo}
                            variant="contained"
                          >
                            Compartir
                            <Box marginRight="0.5rem" />
                            <ShareIcon />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Box>
            <DialogSliderCustom
              open={openDialog}
              title="Permiso de microfono bloqueado"
              description="Para poder utilizar tu traductor de voz a LSM, es necesario conceder permiso al microfono. Si ya has concedido permisos, recarga la página."
              onCancelText=""
              onAcceptText="Aceptar"
              onAccept={() => {
                setOpenDialog(false);
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <ErrorOutlineIcon
            color="error"
            sx={{ fontSize: 48, marginBottom: "1rem" }}
          />
          <Typography variant="h4" gutterBottom>
            Para poder utilizar tu traductor de voz a LSM, es necesario grabar,
            por lo menos, el abecedario completo.
          </Typography>
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => {
              goTo("/grabar");
            }}
          >
            <Typography variant="h6">Comienza a grabar</Typography>
          </Button>
        </Box>
      )}

      <Box
        sx={{
          position: "absolute",
          width: expanded ? "150%" : "1px",
          height: expanded ? "150%" : "1px",
          borderRadius: expanded ? "0%" : "90%",
          backgroundColor: expanded ? "primary.main" : "primary.light",
          transition: "all 1.5s ease",
          zIndex: -1,
        }}
      />

      {loading && <LinearIndeterminate />}
      <CustomAlert
        error={error}
        errorMessage={errorMessage}
        severity="error"
        duration="4000"
      />
    </Container>
  );
}
