import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';

export default function CustomAlert({ error, errorMessage, severity, duration }) {
    const [showAlert, setShowAlert] = useState(error);

    useEffect(() => {
        if (error) {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, duration);
        }
    }, [error, duration]);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '5rem',
            }}
        >
            <Collapse in={showAlert}>
                {showAlert && (
                    <Alert sx={{ width: '100%' }} severity={severity} onClose={() => setShowAlert(false)}>
                        {errorMessage}
                    </Alert>
                )}
            </Collapse>
        </Box>
    );
};