import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, IconButton, Button } from '@mui/material';
import { useAuth } from "../auth/AuthProvider";
import { SERVER_VIDEOS_URL } from "../auth/constants";
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomAlert from "../components/CustomAlert";
import NoSimIcon from '@mui/icons-material/NoSim';
import { useNavigate } from "react-router-dom";
import LinearIndeterminate from "../components/LinearIndeterminate";

export default function Traducciones() {
    const [traductions, setTraductions] = useState([]);
    const [showLightbox, setShowLightbox] = useState(false);
    const [videoTemp, setVideoTemp] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [showTraductions, setShowTraductions] = useState(false);
    const [loading, setLoading] = useState(false);
    const goTo = useNavigate();
    let auth = useAuth();

    useEffect(() => {
        const fetchUserVideos = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${SERVER_VIDEOS_URL}/api/get-user-videos/${auth.getUser().username}/${auth.getApiKey()}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    const videoFiles = await response.json();
                    const traductionsData = videoFiles.map((file) => ({
                        videoSrc: `${SERVER_VIDEOS_URL}/results/${auth.getUser().username}/${file}`,
                        title: file.replace(".mp4", "").replace(/-/g, " "),
                    }));
                    setTraductions(traductionsData);
                    setShowTraductions(true);
                    setError(false);
                    setLoading(false);
                    return;
                } else {
                    setShowTraductions(false);
                    setError(false);
                    setLoading(false);
                    return;
                }
            } catch (error) {
                setError(true);
                setErrorMessage("Error de conexión, intenta de nuevo !");
                setLoading(false);
                return;
            }
        };

        fetchUserVideos();
    }, [auth]);

    const handleMouseEnter = (event) => {
        event.target.play();
    };

    const handleMouseLeave = (event) => {
        event.target.pause();
        event.target.currentTime = 0;
    };

    const handleDownload = (videoSrc) => {
        const link = document.createElement('a');
        link.href = videoSrc;
        link.setAttribute('download', '');
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    const toggleLightbox = () => {
        setShowLightbox(false);
    };

    const handleShowVideo = (video) => {
        setVideoTemp(video);
        setShowLightbox(true);
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '100vh',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {showLightbox && (
                <Box
                    onClick={toggleLightbox}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        gap: '1rem',
                        zIndex: '3',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#1A1A1A',
                            borderRadius: '8px',
                            padding: '1rem',
                            position: 'relative',
                            minWidth: '50%',
                            minHeight: '50%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    color='#DFDFDF'
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {truncateText(videoTemp.title, 100)}
                                </Typography>
                                <hr style={{ borderColor: '#DFDFDF', width: '100%' }} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <video
                                    src={videoTemp.videoSrc}
                                    width="100%"
                                    style={{
                                        objectFit: 'contain',
                                        paddingBottom: '1rem',
                                        maxHeight: '60vh'
                                    }}
                                    controls

                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={toggleLightbox}
                                    sx={{
                                        gap: '0.5rem',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    <ArrowBackIcon />
                                    <Typography
                                        variant='subtitle2'
                                        color='#DFDFDF'
                                    >
                                        Volver
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {showTraductions ? 
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <Grid container spacing={2} marginBottom='2rem' sx={{ display: 'flex', alignItems:'center', justifyContent:"center" }}>
                    {traductions.map((traduction, index) => (
                        <Grid paddingBottom='4rem' paddingTop='1rem' item key={index} xs={7} sm={6} md={5} lg={4} xl={3}>
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "0.5rem",
                                    minWidth: "20%",
                                }}
                            >
                                <Button
                                    variant="text"
                                    sx={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        overflow: 'hidden',
                                    }}
                                    onClick={() => handleShowVideo(traduction)}
                                >
                                    <video
                                        src={traduction.videoSrc}
                                        width="100%"
                                        style={{
                                            objectFit: 'contain'
                                        }}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </Button>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        variant="text"
                                        onClick={() => handleShowVideo(traduction)}
                                        style={{ textTransform: 'none', color: '#1A1A1A' }}
                                    >
                                        <Typography variant="body1">
                                            {truncateText(traduction.title, 25)}
                                        </Typography>
                                    </Button>
                                    <IconButton
                                        aria-label="Descargar video"
                                        onClick={() => handleDownload(traduction.videoSrc)}
                                    >
                                        <DownloadIcon sx={{ color: '#1A1A1A' }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
                :
                <Box sx={{ textAlign: 'center' }}>
                    <NoSimIcon color="disabled" sx={{ fontSize: 48, marginBottom: '1rem', }} />
                    <Typography variant="h4" gutterBottom>
                        No tienes ninguna traducción aún
                    </Typography>
                    <Button variant="text" color="primary" size="large" onClick={() => { goTo('/traductor'); }} >
                        <Typography variant="h6">
                            Comienza a traducir !
                        </Typography>
                    </Button>
                </Box>
            }
            <CustomAlert error={error} errorMessage={errorMessage} severity='error' duration='4000' />
            {loading && <LinearIndeterminate />}
        </Box>
    );
}