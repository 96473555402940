import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DialogSliderCustom from "../components/DialogSliderCustom";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import CustomAlert from "../components/CustomAlert";
import { useAuth } from "../auth/AuthProvider";
import UploadIcon from "@mui/icons-material/Upload";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CircularProgress from "@mui/material/CircularProgress";
import { AUTH_URL, SERVER_VIDEOS_URL } from "../auth/constants";
import { useNavigate } from "react-router-dom";

function Grabar() {
  const [isCameraPermissionGranted, setIsCameraPermissionGranted] =
    useState(false);
  const videoRef = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const [showLightbox, setShowLightbox] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [showError, setShowError] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [actualWord, setActualWord] = useState("");
  const [wordNumber, setWordNumber] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [finishedDictionary, setFinishedDictionary] = useState(false);
  const goTo = useNavigate();
  let auth = useAuth();

  const handleUploadVideo = async () => {
    const blob = new Blob(chunks.current, { type: "video/mp4" });
    const formData = new FormData();
    formData.append(
      "file",
      blob,
      `${actualWord.charAt(0).toUpperCase() + actualWord.slice(1)}.mp4`
    );
    formData.append("username", auth.getUser().username);

    setShowLoading(true);

    await fetch(`${SERVER_VIDEOS_URL}/api/upload-video/${auth.getApiKey()}`, {
      method: "POST",
      headers: {
        "Access-Control-Request-Method": "POST",
      },
      body: formData,
    })
      .then(async (response) => {
        if (response.ok) {
          setErrorResponse("El video se ha subido exitosamente !");
          forceRender();
          forceRender();
          await handleUpdateActualWord();
          await updateActualWord();
          setAlertSeverity("success");
          setShowError(true);
        } else {
          setShowError(true);
          setErrorResponse("El video no se ha podido subido !");
          setAlertSeverity("error");
        }
        chunks.current = [];
      })
      .catch((error) => {
        setShowError(true);
        setErrorResponse("Error al cargar el video !");
        setAlertSeverity("error");
      });

    toggleVideo();
    forceRender();
    setShowLoading(false);
  };

  const forceRender = () => {};

  const handleUpdateActualWord = async () => {
    try {
      const newWord = parseInt(wordNumber) + 1;
      const response = await fetch(
        `${AUTH_URL}/update-word/${auth.getUser()._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.getAccessToken()}`,
          },
          body: JSON.stringify({ newWord: newWord }),
        }
      );

      if (!response.ok) {
        throw new Error("Error al actualizar la palabra");
      }
    } catch (error) {
      setErrorResponse('Servicio no disponible temporalmente, intentalo más tarde.');
    }
  };

  const checkCameraPermission = async () => {
    try {
      setIsCameraPermissionGranted(true);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    updateActualWord();

    checkCameraPermission();
  }, []);

  const toggleVideo = () => {
    setRecordedVideo(null);
  };

  const updateActualWord = async () => {
    try {
      const res = await fetch(`${AUTH_URL}/get-word/${auth.getUser()._id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.getAccessToken()}`,
        },
      });
      if (!res.ok) {
        throw new Error("Error al obtener la palabra actual");
      }

      const dataRes = await res.json();

      if (dataRes.actualWord === "1072") {
        setFinishedDictionary(true);
        return;
      }

      const response = await fetch(
        `${AUTH_URL}/dictionary/${dataRes.actualWord}`
      );
      if (!response.ok) {
        throw new Error("Error al cargar la palabra actual");
      }

      const data = await response.json();
      setWordNumber(data.word.index);
      setActualWord(
        data.word.word.charAt(0).toUpperCase() + data.word.word.slice(1)
      );

        setImageUrl(`${SERVER_VIDEOS_URL}/word-image/${data.word.word}.png`);
    } catch (error) {
      setShowError(true);
      setAlertSeverity("error");
      setErrorResponse(error.message);
    }
  };

  const startRecording = async () => {
    checkCameraPermission();
    if (!isCameraPermissionGranted) {
      setOpenDialog(true);
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(chunks.current, { type: "video/mp4" });
        setRecordedVideo(URL.createObjectURL(blob));
      };

      setShowLightbox(false);
      await mediaRecorder.current.start();
      setTimeout(() => {
        stopRecording();
      }, 3000);
      setIsRecording(true);
    } catch (error) {
      setShowError(true);
      setErrorResponse("Error al acceder a la cámara  !");
      setAlertSeverity("error");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }

    setIsRecording(false);
    setShowLightbox(true);
    forceRender();
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
      }}
    >
      <video
        ref={videoRef}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          position: "fixed",
          zIndex: "-1",
          filter: isRecording ? "none" : "blur(30px)",
        }}
        autoPlay
      />

      {showLightbox && (
        <Box
          onClick={toggleVideo}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#1A1A1A",
              borderRadius: "8px",
              padding: "1rem",
              position: "relative",
              minWidth: "30%",
              minHeight: "40%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {finishedDictionary ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#DFDFDF"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Felicidades has terminado !
                  </Typography>
                  <hr style={{ borderColor: "#DFDFDF", width: "100%" }} />
                </Box>
                <Typography
                  variant="h6"
                  color="#DFDFDF"
                  sx={{
                    textAlign: "center",
                    paddingBottom: "1rem",
                  }}
                >
                  Has terminado de grabar todo el diccionario
                  <br />
                  Ahora puedes comenzar a traducir !
                </Typography>
                <Button
                  onClick={() => {
                    goTo("/traductor");
                  }}
                  aria-label="Traductor"
                  variant="contained"
                  color="primary"
                  sx={{}}
                >
                  Comienza a traducir
                </Button>
              </Box>
            ) : recordedVideo ? (
              <>
                {showLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <CircularProgress color="primary" />
                    <Typography
                      variant="body1"
                      color="#DFDFDF"
                      sx={{ paddingTop: "1rem" }}
                    >
                      Subiendo
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      color="#DFDFDF"
                      sx={{
                        textAlign: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      Confirma tu grabación
                    </Typography>

                    <hr
                      style={{ borderColor: "#DFDFDF", marginBottom: "1rem" }}
                    />
                    <video
                      autoPlay
                      loop
                      src={recordedVideo}
                      style={{
                        maxHeight: "60vh",
                        marginBottom: "1rem",
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingBottom: "1rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={toggleVideo}
                          sx={{
                            gap: "0.5rem",
                          }}
                        >
                          <RestartAltIcon />
                          <Typography variant="subtitle2" color="#DFDFDF">
                            Volver a Grabar
                          </Typography>
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          onClick={handleUploadVideo}
                          sx={{
                            gap: "0.5rem",
                          }}
                        >
                          <UploadIcon />
                          <Typography variant="subtitle2" color="#DFDFDF">
                            Subir Video
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#DFDFDF"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Comienza a grabar
                  </Typography>
                  <hr style={{ borderColor: "#DFDFDF", width: "100%" }} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    color="#DFDFDF"
                    sx={{
                      textAlign: "center",
                      paddingBottom: "1rem",
                    }}
                  >
                    {`#${wordNumber} - ${
                      actualWord.charAt(0).toUpperCase() + actualWord.slice(1)
                    }`}
                  </Typography>

                  <img
                    alt={actualWord}
                    src={imageUrl}
                    style={{ maxWidth: "100%", paddingBottom: "1rem" }}
                  />

                  <Button
                    onClick={startRecording}
                    aria-label="Grabar"
                    variant="contained"
                    color="primary"
                    sx={{}}
                  >
                    Grabar
                    <Box marginRight="0.5rem" />
                    <VideoCameraFrontIcon />
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}

      <DialogSliderCustom
        open={openDialog}
        title="Permiso de camára bloqueado"
        description="Para poder grabar tus videos, es necesario conceder permiso a la cámara. Si ya has concedido permisos, recarga la página."
        onCancelText=""
        onAcceptText="Aceptar"
        onAccept={() => {
          setOpenDialog(false);
        }}
      />

      <CustomAlert
        error={showError}
        errorMessage={errorResponse}
        severity={alertSeverity}
        duration="4000"
      />
    </Box>
  );
}

export default Grabar;
