import { Box, TextField, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import CustomCopyright from './CustomCopyright';
import { useNavigate } from 'react-router-dom';
import { AUTH_URL } from '../auth/constants';
import { useAuth } from '../auth/AuthProvider';

export default function LoginForm({ onErrorResponse, onLoading }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showErrorUsername, setShowErrorUsername] = useState(false);
    const [showErrorPassword, setShowErrorPassword] = useState(false);
    const [errorUsername, setErrorUsername] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    let auth = useAuth();
    const goTo = useNavigate();

    async function handleSubmit(event) {
        event.preventDefault();

        if (!username) {
            setErrorUsername("Ingresa el nombre de usuario !");
            setShowErrorUsername(true);
            return;
        } else {
            setErrorUsername("");
            setShowErrorUsername(false);
        }

        if (!password) {
            setErrorPassword("Ingresa la contraseña !");
            setShowErrorPassword(true);
            return;
        } else {
            setErrorPassword("");
            setShowErrorPassword(false);
        }

        try {
            onLoading(true);
            setUsername(username.trim());
            setPassword(password.trim());
            const response = await fetch(`${AUTH_URL}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username,
                    password,
                }),
            });

            if (response.ok) {
                onErrorResponse("Acceso correcto !");
                const json = await response.json();

                if (json.body.accessToken && json.body.refreshToken) {
                    auth.saveUser(json);
                    onLoading(false);
                    goTo('/traductor');
                }
            } else {
                const json = await response.json();
                const error = json.body.error;
                onErrorResponse(error);
                onLoading(false);
                return;
            }
            onLoading(false);
        } catch (error) {
            onLoading(false);
            onErrorResponse(error);
            return;
        }
    };

    return (
        <Box>
            <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', paddingBottom: '1rem' }}>
                Iniciar sesión
            </Typography>
            <Typography variant="subtitle1" sx={{ paddingBottom: '2rem' }} >
                Bienvenido !
                Ingresa tu correo, y tu contraseña para iniciar sesión
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Nombre de usuario"
                    type='text'
                    name="username"
                    autoComplete="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoFocus
                    error={showErrorUsername}
                    helperText={errorUsername}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="contraseña"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={showErrorPassword}
                    helperText={errorPassword}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Iniciar
                </Button>
            </Box>
            <CustomCopyright sx={{ mt: 8, mb: 4 }} />
        </Box>
    );
}