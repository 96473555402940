import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../auth/AuthProvider";
import { Navigate } from "react-router-dom";
import { AUTH_URL, SERVER_VIDEOS_URL } from "../auth/constants";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import SendIcon from "@mui/icons-material/Send";
import CustomAlert from "../components/CustomAlert";

const CssTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "#DFDFDF",
  },
  "& label.Mui-focused": {
    color: "#DFDFDF",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#DFDFDF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#DFDFDF",
    },
    "&:hover fieldset": {
      borderColor: "#DFDFDF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#DFDFDF",
    },
    "& input::placeholder": {
      color: "#DFDFDF",
    },
  },
});

const Admin = () => {
  let auth = useAuth();
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showGrabaciones, setShowGrabaciones] = useState(false);
  const [showTraducciones, setShowTraducciones] = useState(false);
  const [showUpdateWord, setShowUpdateWord] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedId, setSelelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [showConfirmGrabaciones, setShowConfirmGrabaciones] = useState(false);
  const [showConfirmTraducciones, setShowConfirmTraducciones] = useState(false);
  const theme = useTheme();

  const deleteTraducciones = async () => {
    try {
        const response = await fetch(
          `${SERVER_VIDEOS_URL}/api/delete-all-user-videos/${auth.getApiKey()}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ 
              'username': selectedUser,
              'folder': 'results',
            }),
          }
        );
  
        if (!response.ok) {
          handleCloseBackdrop();
          setError(true);
          setErrorMessage("Error al borrar las traducciones !");
          setSeverity("error");
          return;
        }
  
        setShowUpdateWord(false);
        handleCloseBackdrop();
        setError(true);
        setErrorMessage("Traducciones eliminadas correctamente !");
        setSeverity("success");
        fetchUsers();
      } catch (error) {
        handleCloseBackdrop();
        setError(true);
        setErrorMessage(
          "Error al conectar con el servidor, intentalo mas tarde !"
        );
        setSeverity("error");
      }
  };

  const deleteGrabaciones = async () => {
    try {
      const response = await fetch(
        `${SERVER_VIDEOS_URL}/api/delete-all-user-videos/${auth.getApiKey()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ 
            'username': selectedUser,
            'folder': 'uploads',
          }),
        }
      );

      if (!response.ok) {
        handleCloseBackdrop();
        setError(true);
        setErrorMessage("Error al borrar las grabaciones !");
        setSeverity("error");
        return;
      }

      const response_updateword = await fetch(
        `${AUTH_URL}/update-word/${selectedId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.getAccessToken()}`,
          },
          body: JSON.stringify({ newWord: "1" }),
        }
      );

      if (!response_updateword.ok) {
        handleCloseBackdrop();
        setError(true);
        setErrorMessage("Error al borrar las grabaciones !");
        setSeverity("error");
        return;
      }

      setShowUpdateWord(false);
      handleCloseBackdrop();
      setError(true);
      setErrorMessage("Grabaciones eliminadas correctamente !");
      setSeverity("success");
      fetchUsers();
    } catch (error) {
      handleCloseBackdrop();
      setError(true);
      setErrorMessage(
        "Error al conectar con el servidor, intentalo mas tarde !"
      );
      setSeverity("error");
    }
  };

  const handleShowConfirmTraducciones = () => {
    handleOpenBackdrop();
    setShowConfirmTraducciones(true);
    setVideoList([]);
    handleMenuClose();
  };

  const handleShowConfirmGrabaciones = () => {
    handleOpenBackdrop();
    setShowConfirmGrabaciones(true);
    setVideoList([]);
    handleMenuClose();
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (
      /^\d+$/.test(inputValue) &&
      parseInt(inputValue) > 0 &&
      parseInt(inputValue) <= 1071
    ) {
      setValue(inputValue);
    }
  };

  const handleUpdateWord = async () => {
    handleOpenBackdrop();
    setShowUpdateWord(true);
    setVideoList([]);
    handleMenuClose();
  };

  const updateWord = async () => {
    try {
      const response = await fetch(`${AUTH_URL}/update-word/${selectedId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getAccessToken()}`,
        },
        body: JSON.stringify({ newWord: value }),
      });

      if (!response.ok) {
        handleCloseBackdrop();
        setError(true);
        setErrorMessage("Error al actualizar la palabra");
        setSeverity("error");
        return;
      }

      setShowUpdateWord(false);
      handleCloseBackdrop();
      setError(true);
      setErrorMessage("Palabra actualizada correctamente !");
      setSeverity("success");
      fetchUsers();
    } catch (error) {
      handleCloseBackdrop();
      setError(true);
      setErrorMessage(
        "Error al conectar con el servidor, intentalo mas tarde !"
      );
      setSeverity("error");
    }
  };

  const handleShowTraducciones = async (username) => {
    handleOpenBackdrop();
    setShowTraducciones(true);
    setSelectedUser(username);
    setVideoList([]);

    try {
      setLoading(true);
      const url = `${SERVER_VIDEOS_URL}/api/get-user-videos/${username}/${auth.getApiKey()}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(url, options);
      if (response.ok) {
        const js = await response.json();
        const sortedVideoList = [...js];
        sortedVideoList.sort();
        setVideoList(sortedVideoList);
        setLoading(false);
        return;
      } else if (response.status === 404) {
        setVideoList(["Usuario sin videos !"]);
        setLoading(false);
        return;
      } else {
        setVideoList([]);
        setLoading(false);
        return;
      }
    } catch (error) {
      handleCloseBackdrop();
      setError(true);
      setErrorMessage(error);
      setSeverity("error");
      setLoading(false);
      return;
    }
  };

  const handleShowGrabaciones = async (username) => {
    handleOpenBackdrop();
    setShowGrabaciones(true);
    setSelectedUser(username);
    setVideoList([]);

    try {
      setLoading(true);
      const url = `${SERVER_VIDEOS_URL}/api/get-user-videos-list/${username}/${auth.getApiKey()}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(url, options);
      if (response.ok) {
        const js = await response.json();
        const sortedVideoList = [...js];
        sortedVideoList.sort();
        setVideoList(sortedVideoList);
        setLoading(false);
        return;
      } else if (response.status === 404 || response.status === 400) {
        setVideoList(["Usuario sin videos !"]);
        setLoading(false);
        return;
      } else {
        setVideoList([]);
        setLoading(false);
        return;
      }
    } catch (error) {
      handleCloseBackdrop();
      setError(true);
      setErrorMessage(error);
      setSeverity("error");
      setLoading(false);
      return;
    }
  };

  const handleOpenBackdrop = () => {
    setShowBackdrop(true);
  };

  const handleCloseBackdrop = () => {
    setShowBackdrop(false);
    setShowGrabaciones(false);
    setShowTraducciones(false);
    setShowUpdateWord(false);
    setShowConfirmGrabaciones(false);
    setShowConfirmTraducciones(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${AUTH_URL}/get-users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getAccessToken()}`,
        },
      });
      if (response.ok) {
        const list = await response.json();
        setUsers(list.list);
        setLoading(false);
        return;
      } else {
        console.log(`x ${response.body.error}`);
        setError(true);
        setErrorMessage(response.body.error);
        setSeverity("error");
        setLoading(false);
        return;
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      setSeverity("error");
      return;
    }
  };

  const handleMenuOpen = (event, user) => {
    setSelectedUser(user.username);
    setSelelectedId(user._id);
    setValue(user.actual_word);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!auth.isAdmin) {
    return <Navigate to="/traductor" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflowY: 'auto',
        marginTop: '2rem',
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "90%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F5F5F5",
          borderRadius: "10px",
          flexDirection: "column",
          boxShadow: "-10px 10px 15px rgba(0, 0, 0, 0.2)",
          padding: "1rem",
          marginBottom: '5rem',
        }}
      >
        <Typography variant="h5" sx={{ marginY: "1rem", fontWeight: 600 }}>
          Usuarios
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            backgroundColor: "#DFDFDF",
            padding: "1rem",
          }}
        >
          <Typography
            color="text.secondary"
            sx={{ width: "20%", justifyContent: "left", textAlign: "left" }}
          >
            Nombre de usuario
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ width: "20%", justifyContent: "left", textAlign: "left" }}
          >
            Palabra actual
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ width: "20%", justifyContent: "left", textAlign: "left" }}
          >
            Grabaciones
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ width: "40%", justifyContent: "left", textAlign: "left" }}
          >
            Traducciones
          </Typography>
        </Box>
        {users &&
          users.map((user, key) => (
            <Box
              key={key}
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <Typography
                sx={{ width: "20%", justifyContent: "left", textAlign: "left" }}
              >
                {user.username}
              </Typography>
              <Typography
                sx={{ width: "20%", justifyContent: "left", textAlign: "left" }}
              >
                {user.actual_word}
              </Typography>
              <Box sx={{ width: "20%" }}>
                <Tooltip title="Ver grabaciones">
                  <IconButton
                    onClick={() => {
                      handleShowGrabaciones(user.username);
                    }}
                  >
                    <RecentActorsIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ width: "20%" }}>
                <Tooltip title="Ver traducciones">
                  <IconButton
                    onClick={() => {
                      handleShowTraducciones(user.username);
                    }}
                  >
                    <SubscriptionsIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  justifyContent: "right",
                  textAlign: "right",
                }}
              >
                <Tooltip title="Más acciones">
                  <IconButton
                    onClick={(e) => {
                      handleMenuOpen(e, user);
                    }}
                  >
                    <MoreVertIcon color="action" />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleUpdateWord();
                    }}
                  >
                    <ListItemIcon>
                      <DriveFileRenameOutlineIcon />
                    </ListItemIcon>
                    <ListItemText>Actualizar palabra</ListItemText>
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    onClick={() => {
                      handleShowConfirmTraducciones();
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon color="warning" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: theme.palette.warning.main }}>
                      Borrar traducciones
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleShowConfirmGrabaciones();
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon color="error" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: theme.palette.error.main }}>
                      Borrar grabaciones
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          ))}
      </Box>
      <CustomAlert
        error={error}
        errorMessage={errorMessage}
        severity={severity}
        duration="4000"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
        onClick={handleCloseBackdrop}
      >
        {showConfirmTraducciones && (
          <Box
            sx={{
              backgroundColor: "#1A1A1A",
              borderRadius: "8px",
              padding: "1rem",
              position: "relative",
              minWidth: "30%",
              minHeight: "40%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="#DFDFDF"
                sx={{
                  textAlign: "center",
                }}
              >
                Borrar traducciones de {selectedUser}
              </Typography>
              <hr style={{ borderColor: "#DFDFDF", width: "100%" }} />
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ color: "#DFDFDF", marginBottom: "1rem" }}>
                  ¿Estás seguro de borrar todas las traducciones de{" "}
                  {selectedUser}?
                </Typography>
                <Button
                  variant="contained"
                  onClick={deleteTraducciones}
                  sx={{
                    width: "70%",
                  }}
                >
                  <Typography>Borrar</Typography>
                  <Box marginRight="0.5rem" />
                  <DeleteIcon />
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {showConfirmGrabaciones && (
          <Box
            sx={{
              backgroundColor: "#1A1A1A",
              borderRadius: "8px",
              padding: "1rem",
              position: "relative",
              minWidth: "30%",
              minHeight: "40%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="#DFDFDF"
                sx={{
                  textAlign: "center",
                }}
              >
                Borrar grabaciones de {selectedUser}
              </Typography>
              <hr style={{ borderColor: "#DFDFDF", width: "100%" }} />
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ color: "#DFDFDF", marginBottom: "1rem" }}>
                  ¿Estás seguro de borrar todas las grabaciones de{" "}
                  {selectedUser}?
                </Typography>
                <Button
                  variant="contained"
                  onClick={deleteGrabaciones}
                  sx={{
                    width: "70%",
                  }}
                >
                  <Typography>Borrar</Typography>
                  <Box marginRight="0.5rem" />
                  <DeleteIcon />
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {showUpdateWord && (
          <Box
            sx={{
              backgroundColor: "#1A1A1A",
              borderRadius: "8px",
              padding: "1rem",
              position: "relative",
              minWidth: "30%",
              minHeight: "40%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="#DFDFDF"
                sx={{
                  textAlign: "center",
                }}
              >
                Actualizar palabra actual de {selectedUser}
              </Typography>
              <hr style={{ borderColor: "#DFDFDF", width: "100%" }} />
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CssTextField
                  label="Número"
                  type="number"
                  value={value}
                  color="secondary"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                    style: {},
                  }}
                  inputProps={{
                    min: 1,
                    max: 1071,
                    style: { color: "#DFDFDF" },
                  }}
                  sx={{
                    width: "70%",
                    marginBottom: "1rem",
                  }}
                />
                <Button
                  variant="contained"
                  onClick={updateWord}
                  sx={{
                    width: "70%",
                  }}
                >
                  <Typography>Actualizar</Typography>
                  <Box marginRight="0.5rem" />
                  <SendIcon />
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {showTraducciones && (
          <Box
            sx={{
              backgroundColor: "#1A1A1A",
              borderRadius: "8px",
              padding: "1rem",
              position: "relative",
              minWidth: "30%",
              minHeight: "40%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="#DFDFDF"
                sx={{
                  textAlign: "center",
                }}
              >
                Traducciones de {selectedUser}
              </Typography>
              <hr style={{ borderColor: "#DFDFDF", width: "100%" }} />
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {videoList && (
                <Box
                  sx={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    textAlign: "center",
                  }}
                >
                  {videoList.map((video, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      color="#DFDFDF"
                      sx={{
                        marginBottom: "0.5rem",
                        width: videoList.length > 5 ? "50%" : "100%",
                        flexBasis: "50%",
                      }}
                    >
                      {video}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        )}
        {showGrabaciones && (
          <Box
            sx={{
              backgroundColor: "#1A1A1A",
              borderRadius: "8px",
              padding: "1rem",
              position: "relative",
              minWidth: "30%",
              minHeight: "40%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="#DFDFDF"
                sx={{
                  textAlign: "center",
                }}
              >
                Grabaciones de {selectedUser}
              </Typography>
              <hr style={{ borderColor: "#DFDFDF", width: "100%" }} />
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {videoList && (
                <Box
                  sx={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    textAlign: "center",
                  }}
                >
                  {videoList.map((video, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      color="#DFDFDF"
                      sx={{
                        marginBottom: "0.5rem",
                        width: videoList.length > 5 ? "50%" : "100%",
                        flexBasis: "50%",
                      }}
                    >
                      {video}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Backdrop>
    </Box>
  );
};

export default Admin;
