import React, {  } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Slide,
  useScrollTrigger,
  Box,
} from "@mui/material";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function AppBarCustom() {
  return (
    <Box>
      <HideOnScroll>
        <AppBar>
          <Toolbar>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              Traductor de Voz a LSM
            </Typography>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </Box>
  );
}

export default AppBarCustom;
